<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="过滤">
        <el-input v-model="filter.store" placeholder="名称、编号、拼音" clearable></el-input>
      </el-form-item>
      <el-form-item label="显示停用">
        <el-switch v-model="filter.showAll"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus" @click="addStore" type="primary">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      border
      :row-class-name="tableRowClassName"
      @row-dblclick="editStore"
    >
      <el-table-column label="仓库编号" prop="storeCode" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column label="名称" prop="storeName" show-overflow-tooltip></el-table-column>
      <el-table-column label="仓库位置" prop="storeLocation" show-overflow-tooltip></el-table-column>
      <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="65">
        <template slot-scope="scope">
          <el-tag type="danger" effect="dark" @click="removeItem(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 50, 100, 500]"
      @size-change="getData(1)"
      @current-change="getData()"
      :current-page.sync="pageIndex"
      :page-size.sync="pageSize"
      :total="pageTotal"
    ></el-pagination>
    <div>
      <add-edit :item="item" @success="getData()"></add-edit>
    </div>
  </div>
</template>

<script>
import AddEdit from "@/views/store/Item";
export default {
  components: {
    AddEdit
  },
  data() {
    return {
      filter: {},
      loading: false,
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      item: {}
    };
  },
  watch: {
    filter: {
      handler: function(v) {
        this.getData(1);
      },
      deep: true
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.isStop) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      this.loading = true;
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$get("Store/GetAllStore", params)
        .then(r => {
          let data = r;
          this.data = data.list;
          this.pageTotal = data.pageTotal;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addStore() {
      this.item = {};
    },
    editStore(row) {
      this.item = {
        id: row.id
      };
    },
    removeItem(row) {
      this.$confirm("将删除【" + row.storeName + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("Store/DeleteStore?id=" + row.id).then(r => {
            this.$message.success("操作成功！");
            this.$store.commit("removeBaseInfo", {
              info: "Store",
              id: row.id
            });
            this.getData();
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style>
</style>